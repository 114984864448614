import styled, { css, device } from "@styled";
export const SectionWrap = styled.section`
        .solutionsImgCol{
            text-align:center;
            transition:all .5s ease-in-out;
            position:relative;
            &:nth-child(2){
                .textWrapper{
                    bottom:30px;
                }
            }
        }
        ${device.large} {
            .solutionsImgCol{
                &:nth-child(2){
                    .textWrapper{
                        bottom:30px;
                    }
                }
                &:hover{
                    transform:scale(.975);
                }
            }
        }
    position: relative;
    z-index: 1;
    padding:0 0 40px 0;
    ${device.small} {
        padding:40px 0 60px 0;
    }
    ${device.xlarge} {
        padding:60px 0 80px 0;
    } 
     ${(props) =>
        props.layout === 1 &&
        css`
            .solutionsImgCol:nth-child(2) {
                ${device.medium} {
                    grid-column: 2;
                    grid-row: 1 / span 2;
                }

                ${device.large} {
                    grid-column: 2;
                    grid-row: 1;
                }
            }
    `}
    ${(props) =>
        props.layout === 2 &&
        css`
            .solutionsBoxWrapper{
                 grid-gap: 20px 15px;
                 ${device.large} {
                    grid-gap:20px;
                }
                ${device.xlarge} {
                    grid-gap:22px;
                }
            }
            .solutionsImgCol {  
                &:first-child {   
                    ${device.medium} {  
                        grid-column: span 2;   
                    }  
                }  
                &:last-child {  
                    ${device.medium} {  
                        grid-column: span 2; 
                    } 
                    ${device.medium} {  
                        grid-column: span 2;
                    }  
                }  
            }
    `}
`;
export const SolutionImgWrapper = styled.div`
    display: grid;
    gap:20px;
    justify-content:center;
    align-items:center;
    ${device.medium} {
        grid-template-columns: 1fr 1fr;
    }
    ${device.large} {
        gap: 20px 15px;
        grid-template-columns: repeat(3, 1fr);
    }
    ${device.xlarge} {
        gap:20px;
    }
    ${device.xxlarge} {
        gap: 22px 30px;
    }
`;
export const SolutionImgBox = styled.div`
    img{
        max-width:100%;
    }
`;
export const SolutionText = styled.div`
    position:absolute;
    bottom:35px;
    left:50%;
    transform:translateX(-50%);
    width:90%;
    ${device.xlarge} {
        bottom:50px;
    }
    h4{
        font-size: 20px;
        text-align:center;
        letter-spacing: 0px;
        line-height: 1.333;
        color: #ffffff;
        font-weight: 700;
        margin:0;
        ${device.xlarge} {
            font-size: 24px;
        }
    }
`;
